import React from 'react';
import NotFoundComponent from '../components/NotFoundComponent';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout showFooter={false}>
    <NotFoundComponent />
  </Layout>
);

export default NotFoundPage;
