import React from 'react';
import {
  H2,
  Paragraph,
  IconLink,
  Icon,
  Section,
  Picture,
} from '@lsg/components';

const Grid = require('@lsg/components').Grid;

const NotFoundComponent = () => (
  <Section>
    <Grid>
      <Grid.Row>
        <Grid.Column style={{ margin: 'auto' }} sm={6} xs={12}>
          <Picture
            src={'images/errorIcon.svg'}
            maxWidth={400}
            style={{ margin: 'auto' }}
          />
          <H2>Oops: Hier ist etwas schief gegangen.</H2>
          <div />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={{ margin: 'auto' }} sm={6} xs={12}>
          <Paragraph>
            Leider konnte die von Ihnen gesuchte Seite nicht gefunden werden.
            Bitte versuchen Sie es später noch einmal.
          </Paragraph>
          <IconLink iconName={Icon.Names.interaction___refresh} to={'/'}>
            Zur Startseite
          </IconLink>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Section>
);

export default NotFoundComponent;
